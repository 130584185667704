import React, { useEffect } from "react";
import Layout from "../../layouts/ja";
import Reward from "../../services/RewardsDetail";
import langData from "../../data/static-pages/pages/rewards/ja";

const RedemptionPage = ({ ...props }) => {
  return (
    <Layout location={props.location}>
      <Reward
        baseUrl={props.location.origin}
        params={props.location.search}
        langKey={props.pageContext.langKey}
        pathname={props.location.pathname}
        slug={props["*"]}
        landingUrl={"/ja/reward"}
        loginUrl={"/login"}
        langData={langData}
      />
    </Layout>
  );
};

export default RedemptionPage;
